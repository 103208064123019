<script setup lang="ts">
  import { onMounted, ref, computed } from 'vue'
  import DesLayoutClient from '@/views/DesLayoutClient.vue'
  import { useClientStore } from '@/stores/client'
  import DesHeader from '@/components/DesHeader.vue'
  import DesHeading from '@/components/DesHeading.vue'
  import DesProjectList from '@/components/projects/DesProjectList.vue'
  import DesClientProject from '@/components/projects/DesClientProject.vue'

  const client = useClientStore()

  const search = ref('')
  onMounted(() => client.getDashboard())

  const filteredProjects = computed(() => {
    if (!client.dashboard || !client.dashboard.projects) return []
    return client.dashboard.projects.filter((project) =>
      project.name.toLowerCase().includes(search.value.toLowerCase()),
    )
  })
</script>

<template>
  <DesLayoutClient>
    <template #search>
      <div
        class="relative w-[720px] h-10 rounded-lg border border-black-90 justify-start items-center bg-white text-blue-10"
      >
        <des-icon icon="fa-light fa-search" class="absolute top-2 left-4 w-6 h-6"></des-icon>
        <input
          class="absolute pl-12 pr-4 py-2 top-0 bottom-0 left-0 right-0 bg-transparent outline-none placeholder-black-40 hover:placeholder-blue-10"
          placeholder="Search projects"
          v-model="search"
        />
      </div>
    </template>
    <template #main>
      <DesHeader>
        <DesHeading level="3">My projects</DesHeading>
      </DesHeader>

      <div v-if="client.dashboard">
        <DesProjectList>
          <DesClientProject v-for="project in filteredProjects" :key="project.id" :item="project" />
        </DesProjectList>
      </div>
    </template>
  </DesLayoutClient>
</template>

<style scoped>
  .panel-opened {
    margin-left: max(theme('spacing.40'), theme('spacing.navigation'));
  }
</style>
