import { http } from '@/helpers/http'
import type { TGetAgency } from '@/types/agency'

export function getAgencyFromUrl(): string {
  const searchParams = new URLSearchParams(window.location.search)
  const agencySlug = searchParams.get('agency')
  if (agencySlug) {
    return agencySlug
  } else {
    const hostParts = window.location.hostname.split('.')
    const isIPParts = RegExp(/^[0-9.]+$/).exec(window.location.hostname) !== null
    if (hostParts.length === 4 && !isIPParts) {
      return hostParts[0]
    }
    return ''
  }
}

export async function getAgencyInfo(slug: string): Promise<TGetAgency> {
  return await http.get<TGetAgency>(`/agency/slug/${slug}`)
}

export function redirectToAgency(agency: TGetAgency) {
  const hostParts = window.location.host.split('.')
  if (hostParts.length === 4) {
    if ('redirect' in agency && agency.redirect) {
      hostParts[0] = agency?.slug
    } else {
      hostParts.shift()
    }
  }

  window.location.href = `${window.location.protocol}//${hostParts.join('.')}/${window.location.hash}`
}
