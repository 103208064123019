export type TVoiceResponse = {
  DisplayName: string
  Gender: 'Male' | 'Female'
  LocalName: string | null
  Locale: string
  Name: string
  SampleRateHertz: number | null
  Samples: Record<string, string>
  ShortName: string
  Status: string | null
  StyleList: string[] | null
  VoiceType: 'Standard' | 'Neural'

  locale: string
  voiceName: string
  description: string
  gender: 'Male' | 'Female'
  createdDateTime: string
  properties: {
    publicAvailable: boolean
    styles?: { name: string }[]
    roles?: { name: string }[]
  }
}

export class VoiceTTS {
  public readonly Name: string
  public readonly DisplayName: string
  public readonly LocalName: string | null
  public readonly ShortName: string
  public readonly Gender: 'Female' | 'Male'
  public readonly Locale: string
  public readonly VoiceType: 'Standard' | 'Neural'
  public readonly StyleList: { item: string }[]
  public readonly Samples: Record<string, string>
  public readonly Language: { name: string; label: string }

  constructor(voice: TVoiceResponse, languages: { name: string; label: string }[]) {
    this.DisplayName = voice.DisplayName
    this.Gender = voice.Gender
    this.Language = languages.find((l) => l.name === voice.Locale) ?? { name: 'en-US', label: '🇺🇸 English (US)' }
    this.LocalName = voice.LocalName //can be null
    this.Locale = voice.Locale
    this.Name = voice.Name
    this.Samples = voice.Samples
    this.ShortName = voice.ShortName
    this.StyleList = voice.StyleList?.map((e) => ({ item: e })) || []
    this.VoiceType = voice.VoiceType
  }
}

export class Speaker {
  name: string

  set gender(v) {}
  get gender() {
    return this.voiceTTS.Gender
  }

  set locale(v) {}
  get locale() {
    return this.voiceTTS.Locale
  }

  set displayName(v) {}
  get displayName() {
    return this.customName ? this.customName : this.voiceTTS.DisplayName
  }

  set language(v) {}
  get language() {
    return this.name.match(/^[a-z]{2,3}-[A-Z]{2}/)?.[0] ?? 'en-US'
  }

  constructor(
    voiceName: string,
    public pitch: number = 0,
    public speed: number = 0,
    public style: string,
    public customName: string | null,
    public voiceTTS: VoiceTTS,
  ) {
    this.name = voiceName
  }

  isSameSpeaker(voice: Speaker) {
    return this.name === voice.name && this.customName === voice.customName
  }
}
