import { useUserStore } from '@/stores/user'

const fetchInterceptor = (window: Window) => {
  const user = useUserStore()
  const { fetch: originalFetch } = window

  window.fetch = async (...args) => {
    const [resource, config] = args
    if (config?.method && ['put', 'post'].includes(config?.method?.toLocaleLowerCase())) {
      config.headers = {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }
    const response = await originalFetch(resource, config)
    if (response.status === 403) {
      user.logout()
    }
    return response
  }
}

export default fetchInterceptor
