<script setup lang="ts">
  import { useTourStore } from '@/stores/tour'
  import { computed, ref } from 'vue'
  import DesButton from '@/components/button/DesButton.vue'
  import DesModalClose from '@/components/modal/DesModalClose.vue'
  import DesSpinner from '@/components/DesSpinner.vue'
  import DesButtonIcon from '@/components/button/DesButtonIcon.vue'

  const tour = useTourStore()
  const videoRef = ref<HTMLVideoElement | null>(null)
  const r = 8
  const x0 = computed(() => tour.gap.x + r)
  const x1 = computed(() => tour.gap.x + tour.gap.w - r)
  const y0 = computed(() => tour.gap.y)
  const y1 = computed(() => tour.gap.y + tour.gap.h)

  const top = computed(() => {
    switch (tour.direction) {
      case 'top-left':
      case 'top-right':
      case 'top-center':
        return `${tour.gap.y + tour.gap.h}px`
      default:
        return `${tour.gap.y - 20}px`
    }
  })

  const left = computed(() => {
    switch (tour.direction) {
      case 'top-left':
        return `${tour.gap.x - 20}px`
      case 'top-right':
        return `${tour.gap.x + tour.gap.w + 20}px`
      case 'top-center':
        return `${tour.gap.x + tour.gap.w / 2}px`
      default:
        return `${tour.gap.x + tour.gap.w}px`
    }
  })

  const arrowTop = computed(() => {
    switch (tour.direction) {
      case 'right':
      case 'left':
        return `${tour.gap.y + tour.gap.h / 2 - 8}px`
      default:
        return `${tour.gap.y + tour.gap.h + 12}px`
    }
  })

  const arrowLeft = computed(() => {
    switch (tour.direction) {
      case 'left':
        return `${tour.gap.x + tour.gap.w + 10.5}px`
      case 'right':
        return `${tour.gap.x - 10.5}px`
      default:
        return `${tour.gap.x + tour.gap.w / 2 - 10.5}px`
    }
  })

  function toggleFullscreen() {
    if (videoRef.value) {
      if (videoRef.value.requestFullscreen) {
        videoRef.value.requestFullscreen()
      }
    }
  }
</script>

<template>
  <Transition enter-from-class="opacity-0 pointer-events-none" leave-to-class="opacity-0 pointer-events-none">
    <div v-if="tour.visible" class="absolute transition-all z-50 inset-0">
      <!-- backdrop with gap -->
      <svg :viewBox="`0 0 ${tour.backdrop.width} ${tour.backdrop.height}`" class="absolute inset-0 opacity-50">
        <path
          fill-rule="evenodd"
          class="transition-all fill-black-10"
          :d="`
            M0,0 0,${tour.backdrop.height} ${tour.backdrop.width},${tour.backdrop.height} ${tour.backdrop.width},0
            Z
            M${x0},${tour.gap.y}
            H${x1}
            A${r},${r} 0 0 1 ${x1 + r},${tour.gap.y + r}
            V${y1 - r}
            A${r},${r} 0 0 1 ${x1},${y1}
            H${x0}
            A${r},${r} 0 0 1 ${x0 - r},${y1 - r}
            V${y0 + r}
            A${r},${r} 0 0 1 ${x0},${tour.gap.y}
            Z`"
        />
      </svg>

      <!-- popup with information -->
      <div
        class="absolute w-96 transition-all"
        :style="{ top, left }"
        :class="{
          'px-6': tour.direction === 'left' || tour.direction === 'right',
          'py-6': tour.direction === 'top-left' || tour.direction === 'top-right' || tour.direction === 'top-center',
          '-translate-x-1/2': tour.direction === 'top-center',
          '-translate-x-full': tour.direction === 'right' || tour.direction === 'top-right',
        }"
      >
        <div class="bg-white rounded-xl shadow-xl p-6 relative">
          <DesModalClose @click="tour.hide" class="absolute" />
          <div class="text-xs text-black-60 mb-2">{{ tour.current + 1 }} of {{ tour.list.length }}</div>
          <div class="font-semibold mb-1">{{ tour.item?.title }}</div>
          <div class="text-sm">{{ tour.item?.content }}</div>
          <div class="p-2 bg-white-gray rounded mt-6 relative min-h-48">
            <DesSpinner />
            <video autoplay loop muted :key="tour.item?.name" class="w-full relative" ref="videoRef" preload="auto">
              <source :src="tour.item?.video" type="video/mp4" />
            </video>
            <DesButtonIcon
              @click.stop="toggleFullscreen"
              icon="fa-light fa-arrows-maximize"
              class="absolute top-2 right-2"
            />
          </div>

          <div class="flex justify-between mt-6">
            <DesButton @click="tour.prev" v-if="tour.current" type="gray">
              <des-icon icon="fa-light fa-arrow-left" class="w-icon h-icon" />
              Prev
            </DesButton>
            <div v-else></div>
            <DesButton @click="tour.next" v-if="tour.current + 1 < tour.list.length">Next</DesButton>
            <DesButton @click="tour.hide" v-else>Done</DesButton>
          </div>
        </div>
      </div>

      <!-- arrow for popup -->
      <svg
        width="21"
        height="16"
        class="transition-all fill-white absolute"
        :class="{
          'rotate-90': tour.direction === 'right',
          '-rotate-90': tour.direction === 'left',
        }"
        :style="{ top: arrowTop, left: arrowLeft }"
      >
        <path d="M8.92 1.56a2 2 0 0 1 3.16 0l8.07 10.47a2 2 0 0 1-1.59 3.22H2.44a2 2 0 0 1-1.59-3.22L8.92 1.56Z" />
      </svg>
    </div>
  </Transition>
</template>
