<script setup lang="ts">
  import { nextTick, ref } from 'vue'
  import { onClickOutside, unrefElement } from '@vueuse/core'
  import DesMenuOptions from '@/components/menu/DesMenuOptions.vue'

  defineProps<{
    label?: string
    bottom?: boolean
    right?: boolean
    left?: boolean
    inline?: boolean
    scroll?: boolean
  }>()

  const open = ref<boolean>(false)
  const trigger = ref<HTMLElement>()
  const container = ref<HTMLElement>()
  const handleEscapeKey = (e: KeyboardEvent) => e.key === 'Escape' && toggleMenu(false)
  const toggleMenu = (newState: boolean) => {
    open.value = newState

    if (newState) {
      nextTick(() => unrefElement(container.value)?.querySelector('input')?.focus())
      document.addEventListener('keydown', handleEscapeKey)
    } else {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }

  onClickOutside(trigger, (event: PointerEvent) => {
    if (event.target instanceof HTMLElement) {
      const ignoredElement = event.target.closest('[menu-toggle-ignore]')
      const current = event.target.closest('[data-e="menu"]')
      const siblingMenus = current?.parentElement
        ? [...current.parentElement.children].filter((c) => c.getAttribute('data-e') === 'menu' && c !== current)
        : []

      if (ignoredElement && container.value && !siblingMenus.includes(container.value)) return
    }
    toggleMenu(false)
  })
</script>

<template>
  <div class="select-none relative" :class="inline ? 'inline-flex' : 'block w-full'" ref="container" data-e="menu">
    <span @click.stop.prevent="toggleMenu(!open)" ref="trigger">
      <slot name="label"></slot>

      <slot name="toggle">
        <div class="inset-0 flex w-full items-center justify-center cursor-pointer text-black-10">
          <span
            v-if="label"
            class="px-3 py-2 h-10 flex w-full gap-3 text-sm border leading-none transition-colors items-center"
            :class="{
              'border-blue-50': open,
              'border-black-90': !open,
              'hover:border-black-60': !open && !inline,
              'rounded-lg': inline,
              'rounded': !inline,
            }"
          >
            <div class="grow py-1 whitespace-nowrap overflow-hidden text-ellipsis">{{ label }}</div>
            <des-icon icon="fa-light fa-chevron-down" :class="{ 'rotate-180': open }" class="transition-transform" />
          </span>
          <span
            v-else
            class="p-2 flex items-center justify-center hover:bg-white-gray rounded-lg"
            :class="{ 'bg-white-gray': open }"
          >
            <des-icon icon="fa-light fa-ellipsis-vertical" class="h-6 w-6"></des-icon>
          </span>
        </div>
      </slot>
    </span>

    <DesMenuOptions :bottom="bottom" :left="left" :right="right" :open="open" :scroll="scroll" class="w-full">
      <slot></slot>
    </DesMenuOptions>
  </div>
</template>
