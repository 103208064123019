<script setup lang="ts">
</script>

<template>
  <div class="mb-6">
    <slot></slot>
  </div>
</template>

<style scoped>
</style>
