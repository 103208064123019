<script setup lang="ts">
  import { useAdviceStore } from '@/stores/advice'
  import DesModalContainerBorder from '@/components/modal/DesModalContainerBorder.vue'
  import DesModalClose from '@/components/modal/DesModalClose.vue'
  import DesModalHeader from '@/components/modal/DesModalHeader.vue'
  import DesHeading from '@/components/DesHeading.vue'

  const advice = useAdviceStore()
</script>

<template>
  <div
    class="fixed z-50 transition-[transform,opacity] duration-700 w-96 -translate-y-5 select-none"
    :class="{
      'opacity-0 pointer-events-none translate-y-0': !advice.visible,
      'top-24': advice.position?.y === 'top',
      'bottom-12': advice.position?.y === 'bottom',
      'top-1/2 -mt-20': advice.position?.y === 'center',
      'left-12': advice.position?.x === 'left',
      'right-12': advice.position?.x === 'right',
      'left-1/2 -ml-48': advice.position?.x === 'center',
    }"
  >
    <DesModalContainerBorder class="!w-96 overflow-hidden">
      <div
        class="absolute -left-4 -top-11 w-64 h-64 rounded-full blur-2xl"
        style="background: linear-gradient(135deg, rgba(57, 169, 229, 0.14) 0%, rgba(131, 23, 255, 0.14) 100%)"
      ></div>
      <DesModalClose class="absolute" @click="advice.hide()" />

      <TransitionGroup leaveToClass="opacity-0 absolute" enterFromClass="opacity-0 absolute">
        <template v-for="(item, key) in advice.items" :key="key">
          <div v-if="advice.index === key">
            <DesModalHeader>
              <DesHeading level="5" class="relative flex items-center gap-2">
                <img :src="`/dashboard/icons/wordgenie-tip.svg`" class="w-5 h-5 inline" alt="" />
                {{ item.title }}
              </DesHeading>
            </DesModalHeader>
            <div class="relative ml-7 text-sm" v-html="item.content"></div>
          </div>
        </template>
      </TransitionGroup>

      <div class="relative flex gap-3 h-1.5 justify-center mt-4" v-if="advice.items.length > 1">
        <div
          class="w-6 h-full shrink-0 hover:bg-blue-70 bg-black-90 rounded-full cursor-pointer transition-colors"
          v-for="n in advice.items.length"
          :key="n"
          :class="{ 'bg-blue-70': n === advice.index + 1 }"
          @click="advice.index = n - 1"
        ></div>
      </div>
    </DesModalContainerBorder>
  </div>
</template>
