import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { HTTPError, http } from '@/helpers/http'
import { useSnackbarStore } from '@/stores/snackbar'
import type { TClientEditRequest, TClientList, TClientResponse, TClientDashboard } from '@/types/client'
import { type TStorageUser, type TUser } from '@/types/user'
import { usePopupStore } from '@/stores/popup'
import { Validators } from '@/helpers/validators'
import { useLoaderStore } from '@/stores/loader'
import { useStorage } from '@vueuse/core'
import { useUserStore } from '@/stores/user'

export const useClientStore = defineStore('client', () => {
  const sortOptions = [
    { label: 'Newest', name: 'newest', icon: 'fa-clock' },
    { label: 'Oldest', name: 'oldest', icon: 'fa-clock' },
  ]

  const clients = ref<TClientList[]>([])
  const client = ref<TClientResponse | null>(null)
  const dashboard = ref<TClientDashboard | null>(null)
  const sort = ref(sortOptions[0])
  const search = ref<string>('a')
  const snack = useSnackbarStore()

  async function performLogout() {
    await http.post('/clients/logout', {}, true)
  }

  async function getDashboard() {
    try {
      dashboard.value = await http.get<TClientDashboard>(`/clients/dashboard`)
    } catch {
      dashboard.value = null
    }
  }

  async function getClients(): Promise<void> {
    try {
      clients.value = await http.get<TClientList[]>('/clients')
    } catch {
      clients.value = []
    }
  }

  async function getClientDetail(id: string | number): Promise<void> {
    try {
      client.value = await http.get<TClientResponse>(`/clients/${id}`)
    } catch {
      client.value = null
    }
  }

  async function addEditClient(user: TClientEditRequest, id?: number): Promise<boolean> {
    console.dir({ user, id })
    try {
      const url = id ? `/clients/${id}` : '/clients'
      await http.post(url, user, true)
      return true
    } catch (e: unknown) {
      if (e instanceof HTTPError) {
        try {
          const error = JSON.parse(e.response)
          if (error.user_friendly_message) {
            snack.add(error.user_friendly_message)
          }
        } catch {
          snack.add(e.response)
        }
      }
      return false
    }
  }

  async function deleteClient(id: number): Promise<boolean> {
    try {
      await http.delete(`/clients/${id}`, true)
      return true
    } catch {
      return false
    }
  }

  const filteredAndSorted = computed(() => {
    const filtered = clients.value.filter((client) => {
      return (
        (client.first_name && client.first_name.toLowerCase().includes(search.value.toLowerCase())) ||
        (client.last_name && client.last_name.toLowerCase().includes(search.value.toLowerCase())) ||
        client.email.toLowerCase().includes(search.value.toLowerCase())
      )
    })

    return filtered.sort((a, b) => {
      if (sort.value.name === 'newest') {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      } else {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      }
    })
  })

  async function editUserInfo(email: string, user: TUser): Promise<void> {
    const popup = usePopupStore()
    if (email === '') {
      await popup.alert('Please enter your email!')
      return
    }
    if (!Validators.validateEmail(email)) {
      await popup.alert('Please check the email, it is not valid!')
      return
    }
    const loader = useLoaderStore()
    const errMsg = 'Error saving the changes! Please try again!'
    try {
      loader.show('Please wait!')
      const res: string = await http.post(
        '/clients/edit',
        {
          user,
        },
        true,
      )
      loader.hide()
      await popup.alert(res)
    } catch {
      loader.hide()
      await popup.alert(errMsg)
      return
    }
    const storage = useStorage('user', {} as TStorageUser)
    storage.value.value = user
    storage.value.time = Date.now()

    if (email !== user.email) {
      useUserStore().logout()
    }
  }

  async function changePassword(oldPassword: string, newPassword: string, confirmPassword: string): Promise<void> {
    const popup = usePopupStore()
    if (newPassword !== confirmPassword) {
      await popup.alert('Passwords do not match!')
      return
    }
    if (!newPassword || newPassword.length < 6) {
      await popup.alert('Password length is too short!')
      return
    }
    if (oldPassword === newPassword) {
      await popup.alert('Old and new password are the same!')
      return
    }
    const loader = useLoaderStore()
    const defaultErrorMsg = 'Error changing the password! Please try again!'
    try {
      loader.show('Please wait!')
      const response = await http.post(
        '/clients/change_password',
        {
          old_password: oldPassword,
          new_password: newPassword,
        },
        true,
      )
      loader.hide()
      await popup.alert(response)
    } catch (e: any) {
      loader.hide()
      await popup.alert(e?.message ?? defaultErrorMsg)
      return
    }
  }

  return {
    clients,
    client,
    dashboard,
    sortOptions,
    sort,
    search,
    filteredAndSorted,
    performLogout,
    getDashboard,
    getClients,
    getClientDetail,
    addEditClient,
    deleteClient,
    editUserInfo,
    changePassword,
  }
})
