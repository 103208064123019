<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useModalStore } from '@/stores/modal'
import DesMenu from '@/components/menu/DesMenu.vue'
import DesMenuItem from '@/components/menu/DesMenuItem.vue'
import DesMenuSeparator from '@/components/menu/DesMenuSeparator.vue'
import DesBarAvatar from '@/components/bar/DesBarAvatar.vue'
import DesBarCanny from '@/components/bar/DesBarCanny.vue'
import DesBarMenuUser from '@/components/bar/DesBarMenuUser.vue'
import DesBarHelp from '@/components/bar/DesBarHelp.vue'
import DesButton from '@/components/button/DesButton.vue'

defineProps<{ hideCreate?: boolean }>()

const user = useUserStore()
const modal = useModalStore()
const router = useRouter()

async function logout () {
  await user.performLogout()
  user.logout()
}

async function questionnaireData () {
  await modal.open(() => import('@/components/modal/DesModalQuestionnaireData.vue'))
}

function can (action: string): boolean {
  return user.user.permissions[action] === 1
}
</script>

<template>
  <div class="flex gap-6 items-center shrink-0">
    <DesBarHelp/>
    <DesBarCanny/>

    <DesButton
      v-if="!hideCreate"
      @click="router.push('/creator')"
      v-des-track="'create-bar-clicked'"
      v-des-tour="'bar-create'"
    >
      <des-icon icon="fa-light fa-plus"/>
      Create
    </DesButton>

    <DesMenu bottom>
      <template #toggle>
        <DesBarAvatar/>
      </template>
      <DesBarMenuUser/>
      <DesMenuSeparator/>
      <DesMenuItem to="/profile" icon="fa-light fa-user">Account settings</DesMenuItem>
      <DesMenu left v-if="user.user?.permissions?.superuser === 1">
        <template #toggle>
          <DesMenuItem icon="fa-light fa-user-tie" menu-toggle-ignore more class="text-blue-60">Admin</DesMenuItem>
        </template>
        <DesMenuItem to="/admin">Admin</DesMenuItem>
        <DesMenuItem v-if="can('tips.update')" to="/admin/tips">Tips management</DesMenuItem>
        <DesMenuItem to="/admin/variant-niche-images">Variant theme images</DesMenuItem>
        <DesMenuItem v-if="can('imagesfortags.update')" to="/admin/image-for-themes">Images for themes</DesMenuItem>
        <DesMenuItem to="/admin/poll-import">Import poll results</DesMenuItem>
        <DesMenuItem to="/admin/poll-audiobook">Audiobook poll results</DesMenuItem>
        <DesMenuItem to="/admin/themes">Themes</DesMenuItem>
        <DesMenuItem to="/admin/templates-tags">Templates themes</DesMenuItem>
        <DesMenuItem @click="questionnaireData()">Get questionnaire CSV data</DesMenuItem>
        <DesMenuItem to="/admin/suggestions">Suggestions</DesMenuItem>
        <DesMenuItem to="/admin/bot">Cancellation bot old</DesMenuItem>
        <DesMenuItem to="/admin/refund-bot">Cancellation bot</DesMenuItem>
      </DesMenu>
      <DesMenuItem icon="fa-light fa-arrow-right-from-bracket" @click="logout()">Log out</DesMenuItem>
    </DesMenu>
  </div>
</template>
