<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { useDateFormat } from '@vueuse/core'
  import { useProjectsMenuStore } from '@/stores/list-item-menu'
  import { useProjectsStore } from '@/stores/projects'
  import { useProjectsBarStore } from '@/stores/floating-bars'
  import { useSnackbarStore } from '@/stores/snackbar'
  import type { TProject } from '@/types/projects'
  import DesHover from '@/components/DesHover.vue'
  import DesHoverMenu from '@/components/DesHoverMenu.vue'
  import DesButtonIcon from '@/components/button/DesButtonIcon.vue'

  const props = defineProps<{
    item: TProject
    disableCheckboxes?: boolean
  }>()

  const projects = useProjectsStore()
  const bar = useProjectsBarStore()
  const snack = useSnackbarStore()
  const { open } = useProjectsMenuStore()

  const formatDate = (date: string) => useDateFormat(date, 'MMMM DD, YYYY', { locales: 'en-US' })
  const preview = (p: TProject) => window.open(`${location.origin}/clients/projects/${p.id}`)
  const tile = ref<HTMLElement | null>(null)
  const checked = ref<boolean>(bar.is(props.item))

  watch(
    () => bar.list.length,
    () => (checked.value = bar.is(props.item)),
  )

  const imageSrc = computed(() => {
    const prefix = location.origin + '/assets/images/projects/'
    const postfix = '?updated=' + encodeURI(props.item.updated_at)
    const base = props.item.cover_regular || `project-${props.item.id}.png`

    return prefix + base + postfix
  })
</script>

<template>
  <div class="flex flex-col gap-1.5" ref="tile">
    <div
      class="relative h-52 px-4 pt-4 bg-white-gray rounded-xl justify-center items-start inline-flex overflow-hidden cursor-pointer border"
      :class="checked ? 'border-blue-50' : 'border-transparent'"
    >
      <div class="h-64 flex-col justify-start items-center inline-flex">
        <img
          class="rounded-md max-h-full max-w-full w-auto h-auto shadow"
          :src="imageSrc"
          loading="lazy"
          alt=""
          width="0"
          height="0"
        />
      </div>
      <DesHover data-sortable-handler @click.stop="preview(item)">
        <DesHoverMenu>
          <DesButtonIcon @click.stop="preview(item)" icon="fa-light fa-eye" />
        </DesHoverMenu>
      </DesHover>
    </div>
    <div>
      <input
        class="text-sm max-w-full w-full py-1 pr-10 bg-transparent font-medium text-black-10 border transition-all border-transparent rounded-lg outline-none overflow-ellipsis overflow-hidden"
        ref="input"
        :value="item.name"
        readonly="true"
      />
      <div class="mt-1 text-black-40 text-xs leading-none">
        {{ item.project_page_updated_at ? formatDate(item.project_page_updated_at).value : '' }}
      </div>
    </div>
  </div>
</template>
