<template>
  <div class="shadow relative flex rounded-xl p-0.5 w-modal-md gradient">
    <div class="w-full h-full grow shrink p-8 bg-white rounded-xl">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
  .gradient {
    background: linear-gradient(90deg, theme('colors.blue.20') 0%, theme('colors.blue.50') 100%);
  }
</style>
