import { http } from '@/helpers/http'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { Speaker, VoiceTTS, type TVoiceResponse } from '@/types/voice'
import { Node } from '@tiptap/pm/model'
import type { Editor } from '@tiptap/vue-3'

export const useVoiceStore = defineStore('voices', () => {
  const allLanguages = [
    { name: 'ar-EG', label: '🇪🇬 Arabic (Egypt)' },
    { name: 'ar-SA', label: '🇸🇦 Arabic (Saudi Arabia)' },
    { name: 'bg-BG', label: '🇧🇬 Bulgarian' },
    { name: 'ca-ES', label: '🇪🇸 Catalan (Spain)' },
    { name: 'zh-HK', label: '🇭🇰 Chinese (Cantonese, Traditional)' },
    { name: 'zh-CN', label: '🇨🇳 Chinese (Mandarin, Simplified)' },
    { name: 'zh-TW', label: '🇹🇼 Chinese (Taiwanese Mandarin)' },
    { name: 'hr-HR', label: '🇭🇷 Croatian' },
    { name: 'cs-CZ', label: '🇨🇿 Czech' },
    { name: 'da-DK', label: '🇩🇰 Danish' },
    { name: 'nl-NL', label: '🇳🇱 Dutch' },
    { name: 'en-AU', label: '🇦🇺 English (Australia)' },
    { name: 'en-CA', label: '🇨🇦 English (Canada)' },
    { name: 'en-IN', label: '🇮🇳 English (India)' },
    { name: 'en-IE', label: '🇮🇪 English (Ireland)' },
    { name: 'en-GB', label: '🇬🇧 English (UK)' },
    { name: 'en-US', label: '🇺🇸 English (US)' },
    { name: 'et-EE', label: '🇪🇪 Estonian (Estonia)' },
    { name: 'fi-FI', label: '🇫🇮 Finnish' },
    { name: 'fil-PH', label: '🇵🇭 Filipino (Philippines)' },
    { name: 'fr-CA', label: '🇨🇦 French (Canada)' },
    { name: 'fr-FR', label: '🇫🇷 French (France)' },
    { name: 'fr-CH', label: '🇨🇭 French (Switzerland)' },
    { name: 'de-AT', label: '🇦🇹 German (Austria)' },
    { name: 'de-DE', label: '🇩🇪 German (Germany)' },
    { name: 'de-CH', label: '🇨🇭 German (Switzerland)' },
    { name: 'el-GR', label: '🇬🇷 Greek' },
    { name: 'he-IL', label: '🇮🇱 Hebrew (Israel)' },
    { name: 'hi-IN', label: '🇮🇳 Hindi (India)' },
    { name: 'hu-HU', label: '🇭🇺 Hungarian' },
    { name: 'ga-IE', label: '🇮🇪 Irish (Ireland)' },
    { name: 'id-ID', label: '🇮🇩 Indonesian' },
    { name: 'it-IT', label: '🇮🇹 Italian' },
    { name: 'ja-JP', label: '🇯🇵 Japanese' },
    { name: 'ko-KR', label: '🇰🇷 Korean' },
    { name: 'lv-LV', label: '🇱🇻 Latvian (Latvia)' },
    { name: 'lt-LT', label: '🇱🇹 Lithuanian (Lithuania)' },
    { name: 'mt-MT', label: '🇲🇹 Maltese (Malta)' },
    { name: 'ms-MY', label: '🇲🇾 Malay' },
    { name: 'nb-NO', label: '🇳🇴 Norwegian' },
    { name: 'pl-PL', label: '🇵🇱 Polish' },
    { name: 'pt-BR', label: '🇧🇷 Portuguese (Brazil)' },
    { name: 'pt-PT', label: '🇵🇹 Portuguese (Portugal)' },
    { name: 'ro-RO', label: '🇷🇴 Romanian' },
    { name: 'ru-RU', label: '🇷🇺 Russian' },
    { name: 'sk-SK', label: '🇸🇰 Slovak' },
    { name: 'sl-SI', label: '🇸🇮 Slovenian' },
    { name: 'es-MX', label: '🇲🇽 Spanish (Mexico)' },
    { name: 'es-ES', label: '🇪🇸 Spanish (Spain)' },
    { name: 'sv-SE', label: '🇸🇪 Swedish' },
    { name: 'ta-IN', label: '🇮🇳 Tamil (India)' },
    { name: 'te-IN', label: '🇮🇳 Telugu (India)' },
    { name: 'th-TH', label: '🇹🇭 Thai' },
    { name: 'tr-TR', label: '🇹🇷 Turkish (Turkey)' },
    { name: 'vi-VN', label: '🇻🇳 Vietnamese' },
  ]

  const breakStrength = [
    { label: 'None', name: 'none' },
    { label: 'X-weak', name: 'x-weak' },
    { label: 'Weak', name: 'weak' },
    { label: 'Default', name: 'medium' },
    { label: 'Strong', name: 'strong' },
    { label: 'X-strong', name: 'x-strong' },
  ]

  const languages = computed(() => {
    return allLanguages.filter((l) => voices.value.some((v) => v.Locale === l.name))
  })

  const genders = ref([
    { name: 'male', label: '👨 Male' },
    { name: 'female', label: '👩 Female' },
  ])
  const voices = ref<VoiceTTS[]>([])
  const mainSpeaker = ref<Speaker | null>(null)
  const currentSpeaker = ref<Speaker | null>(null)
  const documentSpeakers = ref<Speaker[]>([])
  const player = new Audio()
  const playerVoice = ref<Speaker | null>(null)

  async function getVoices() {
    try {
      const res = await http.get<TVoiceResponse[]>('/tts/list_voices')
      voices.value = res.map((voice) => new VoiceTTS(voice, allLanguages))
    } catch (error) {
      console.error(error)
      voices.value = []
    }
  }

  const defaultSpeaker = computed(() => {
    const voiceName = 'en-US-JennyNeural'
    const voice = voices.value.find((v) => v.Name === voiceName)

    if (!voice) {
      return null
    }

    return new Speaker(voiceName, 0, 0, 'Default', null, voice)
  })

  function findByName(name: string): VoiceTTS | undefined {
    return voices.value.find((v) => v.Name === name)
  }

  function readDocumentSpeakers(editor: Editor): void {
    documentSpeakers.value = []

    if (mainSpeaker.value) {
      documentSpeakers.value.push(mainSpeaker.value)
    } else if (defaultSpeaker.value) {
      documentSpeakers.value.push(defaultSpeaker.value)
    }

    editor.state.doc.descendants((node) => {
      if (node.type.name === 'audiobookParagraph') {
        const speaker = getSpeakerFromNode(node)

        if (speaker) {
          const alreadyAdded = documentSpeakers.value.find((e) => {
            return (
              e.displayName === speaker.displayName &&
              e.gender === speaker.gender &&
              e.locale === speaker.locale &&
              e.name === speaker.name
            )
          })

          if (!alreadyAdded) documentSpeakers.value.push(speaker)
        }
      }
    })
  }

  function play(voice: Speaker) {
    const sample = voice.style

    player.oncanplay = player.play
    player.onerror = () => {
      player.src = ''
      playerVoice.value = null
    }

    player.src = voice.voiceTTS.Samples[sample]
    playerVoice.value = voice
  }

  function pause() {
    player.pause()
    player.src = ''
    playerVoice.value = null
  }

  function getSpeakerFromNode(node: Node): Speaker | null {
    const { voice, style, speed, pitch, customName } = node.attrs ?? {}
    const voiceTTS = findByName(voice)

    if (voiceTTS) {
      return new Speaker(voice, ~~pitch, ~~speed, style, customName || null, voiceTTS)
    }

    return null
  }

  return {
    getVoices,
    languages,
    breakStrength,
    genders,
    voices,
    defaultSpeaker,
    mainSpeaker,
    currentSpeaker,
    documentSpeakers,
    findByName,
    readDocumentSpeakers,
    getSpeakerFromNode,
    play,
    pause,
    playerVoice,
  }
})
