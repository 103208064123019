import { useUserStore } from '@/stores/user'
import { isStage, isProduction } from '@/helpers/envCheck'
import type { TUser } from '@/types/user'
import type { TClient } from '@/types/agency'

declare global {
  interface Window {
    __settingUpBugSnap: boolean
    Bugsnag: {
      start: Function
      setUser: Function
      notify: Function
    }
    build_version: string
  }
}
export default class BugSnagDes {
  private static _instance: BugSnagDes | null = null
  static get instance(): BugSnagDes {
    if (!BugSnagDes._instance) {
      BugSnagDes._instance = new BugSnagDes()
    }
    return BugSnagDes._instance
  }

  setBugSnag(user: TUser | TClient) {
    if (!window || !window?.location?.href) {
      //prevent crash in karma test
      return
    }
    if (!(isProduction() || isStage())) {
      return
    }

    const userStore = useUserStore()
    if (window?.__settingUpBugSnap !== true) {
      window.__settingUpBugSnap = true
      const apiKey = '5aa513b6454e80a0d681cae03f5cfe02'
      const e = document.createElement('script')
      e.type = 'text/javascript'
      e.async = !0
      e.src = '//d2wy8f7a9ursnm.cloudfront.net/v7/bugsnag.min.js'
      e.addEventListener('error', (e) => console.log(e))
      e.addEventListener('load', () => {
        window.Bugsnag.start({
          appVersion: window?.build_version,
          apiKey: apiKey,
        })
        if (!user) {
          user = userStore.user
        }
        if (user) {
          const userFullName = userStore.getUserFullName(user)
          window.Bugsnag.setUser(user.id, user.email, userFullName)
        }
      })

      const g = document.getElementsByTagName('script')[0]
      g?.parentNode?.insertBefore(e, g)
    } else if (!user && window.Bugsnag) {
      user = userStore.user
      if (user) {
        const userFullName = userStore.getUserFullName(user)
        window.Bugsnag.setUser(user.id, user.email, userFullName)
      }
    } else if (user && window.Bugsnag) {
      const userFullName = userStore.getUserFullName(user)
      window.Bugsnag.setUser(user.id, user.email, userFullName)
    }
  }
}
