<script setup lang="ts">
  import DesIconGold from '@/components/DesIconGold.vue'
  import { useUpgradeStore } from '@/stores/upgrade'
  import { ref } from 'vue'
  import { Products } from '@/helpers/product'

  const upgrade = useUpgradeStore()
  defineProps<{
    title: string
    image?: string
    video?: string
    to?: string
    locked?: boolean
    unlockedFor?: number
  }>()

  const videoRef = ref<HTMLVideoElement | null>(null)

  async function playVideo() {
    if (videoRef.value) {
      try {
        await videoRef.value.play()
      } catch {
        // video is not ready to play or has been removed
      }
    }
  }

  function pauseVideo() {
    if (videoRef.value) {
      videoRef.value.pause()
      videoRef.value.currentTime = 0
    }
  }
</script>

<template>
  <RouterLink
    :to="!locked && to ? to : '/'"
    class="w-56 h-full relative snap-start bg-white rounded-lg flex flex-col border border-black-90 align-stretch hover:shadow-lg cursor-pointer shrink-0 select-none"
    @mouseenter="playVideo"
    @mouseleave="pauseVideo"
  >
    <div class="relative border-b border-black-90 grow shrink-1 overflow-hidden rounded-tl-lg rounded-tr-lg">
      <img
        v-if="image"
        alt=""
        :src="image"
        class="h-full absolute bottom-0 left-1/2 -translate-x-1/2 overflow-hidden max-w-none"
      />
      <video
        v-else-if="video"
        ref="videoRef"
        class="absolute -top-2 -left-px -right-px w-56 overflow-hidden max-w-none pointer-events-none bg-transparent"
        playsinline
        loop
        muted
        preload="auto"
        disablePictureInPicture
        disableRemotePlayback
        x-webkit-airplay="deny"
      >
        <source :src="video" type="video/mp4" />
      </video>
    </div>
    <div class="h-8 grow-0 shrink text-sm font-medium leading-8 px-2">{{ title }}</div>
    <div
      class="absolute inset-0 bg-white bg-opacity-50 text-transparent flex items-center justify-center font-medium text-center p-6 text-sm font-medium leading-none transition-colors hover:bg-opacity-75 hover:text-blue-10"
      v-if="locked"
      @click="upgrade.showUpgradeModal()"
    >
      Upgrade needed in order to enjoy this feature
      <DesIconGold
        :icon="unlockedFor === Products.Premium ? 'fa-solid fa-crown' : 'fa-solid fa-star'"
        class="absolute left-3 top-3"
      />
    </div>
  </RouterLink>
</template>
