import { Readability } from '@mozilla/readability'
import { Html2Pdf } from '@/helpers/html2pdf'
import { applyRTL } from '@/helpers/rtl'

type ReadabilityResult = {
  title: string
  content: string
  textContent: string
  length: number
  excerpt: string
  byline: string
  dir: string
  siteName: string
  lang: string
  publishedTime: string
}

type HttpResponse = {
  data: {
    status?: string
    content: string
    url?: string
    title?: string
    base?: string
  }
  status: string
}

type TPhpReadabilityResponse = {
  url: string
  content: string
  title: string
  status: string
  base: string
  message: string
}

export class ReadabilityWithIframe {
  private static readonly TITLE_PLACEHOLDER = 'Article title'
  private static readonly READABILITY_FAILURE = 'READABILITY_FAILURE'

  private iframe: HTMLIFrameElement | null = null

  constructor(iframeId: string = 'readability-iframe') {
    if (typeof Readability === 'undefined') {
      throw new Error('Readability from mozilla lib is not ready or is not installed!')
    }
    this.createIframe(iframeId)
  }

  private createIframe(iframeId: string): void {
    this.iframe = document.getElementById(iframeId) as HTMLIFrameElement
    if (!this.iframe) {
      this.iframe = document.createElement('iframe')
      this.iframe.id = iframeId
      document.body.appendChild(this.iframe)
    }
  }

  stringMayContainErrors(html: string) {
    const noHtmlMarkupsCondition = html.indexOf('<htm') === -1 && html.indexOf('<HTM') === -1
    const errorWordCondition = html.indexOf('error') > -1 || html.indexOf('Error') > -1 || html.indexOf('ERROR') > -1
    return noHtmlMarkupsCondition && errorWordCondition
  }

  private cleanHtmlFromErrors(html: string) {
    const parts = html.split('<!DOCTYPE')
    if (parts.length === 2 && parts[0].length > 0 && this.stringMayContainErrors(parts[0])) {
      return '<!DOCTYPE' + parts[1]
    }
    return html
  }

  async getUrlContent(url: string): Promise<HttpResponse> {
    try {
      const response = await fetch('/proxyRemotePageContent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
      })
      const data = await response.json()
      console.log('>', data)
      return { status: response.ok ? 'success' : 'error', data }
    } catch (error) {
      return { status: 'error', data: { content: error ? error.toString() : '' } }
    }
  }

  private getCleanedBodyHtml(response: string) {
    const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
    while (SCRIPT_REGEX.test(response)) {
      response = response.replace(SCRIPT_REGEX, '')
    }

    const parser = new DOMParser()
    const doc = parser.parseFromString(response, 'text/html')
    const titleNodes = doc.getElementsByTagName('title')
    const titleNode = titleNodes && titleNodes.length > 0 ? titleNodes[0] : null
    let title = ''
    if (titleNode && titleNode.innerText) {
      title = titleNode.innerText.trim()
      if (title.indexOf('\n') > 0) {
        title = title.split('\n')[0]
      }
      if (title.indexOf('<!--') > 0) {
        title = title.split('<!--')[0]
      }
    }

    removeByTags(doc, ['script', 'link', 'style'])
    removeByClassList(doc, ['uael-modal-parent-wrapper', 'pum-overlay', 'keep-reading-wrapper'])

    return { html: doc.body.innerHTML, title: title }

    function removeByTags(doc: Document, tags: string[]) {
      tags.forEach(function (tag) {
        Array.from(doc.getElementsByTagName(tag)).forEach((node) => node.remove())
      })
    }

    function removeByClassList(doc: Document, classList: string[]) {
      classList.forEach(function (c) {
        Array.from(doc.getElementsByClassName(c)).forEach((node) => node.remove())
      })
    }
  }

  setBodyHtml(html: string) {
    if (this.iframe?.contentDocument) {
      const iframeBody = this.iframe?.contentDocument?.body
      iframeBody.innerHTML = html
    }
  }

  setTitleAndBase(title: string, base: string) {
    let headHtml = '<title>' + title + '</title>'
    if (base) {
      headHtml += '\n <base href="' + base + '" />'
    }
    if (this.iframe?.contentDocument) {
      const iframeHead = this.iframe?.contentDocument?.head
      iframeHead.innerHTML = headHtml
    }
  }

  getUri(url: string, base: string) {
    const loc = document.createElement('a')
    loc.href = url
    // IE doesn't populate all link properties when setting .href with a relative URL,
    // however .href will return an absolute URL which then can be used on itself
    // to populate these additional fields.
    if (loc.host === '') {
      //noinspection SillyAssignmentJS
      // TODO check WTF?
      // loc.href = loc.href
    }
    if (!base) {
      base = loc.protocol + '//' + loc.host + loc.pathname.slice(0, loc.pathname.lastIndexOf('/') + 1)
    }
    return {
      spec: loc.href,
      host: loc.host,
      prePath: loc.protocol + '//' + loc.host,
      scheme: loc.protocol.slice(0, loc.protocol.indexOf(':')),
      pathBase: base,
    }
  }

  doReadability(url: string, html: string, base: string) {
    const clean = this.getCleanedBodyHtml(applyRTL(html))
    html = clean.html
    let title = clean.title
    const document = this.iframe?.contentDocument
    const uri = this.getUri(url, base)
    this.setBodyHtml(html)
    if (!base) {
      //try to guess a base url, this will not work 100%
      base = url
    }
    if (!document) {
      throw new Error(`No document for readability`)
    }
    this.setTitleAndBase(title, base)
    const redArt = new Readability(document, { debug: false })
    // noinspection JSValidateTypes,JSVoidFunctionReturnValueUsed
    /** @type {{title: string,content:string}} article**/
    const article = redArt.parse()
    title = article?.title || ReadabilityWithIframe.TITLE_PLACEHOLDER
    if (!title) {
      /*
        const doc = new EditedDocument(null)
        title = doc.detectDocumentTitle(document, ReadabilityWithIframe.TITLE_PLACEHOLDER)
        */
      // TODO find title like in EditedDocument.detectDocumentTitle
    }
    this.setBodyHtml('')
    return { article: article, title: title }
  }

  onIframeReadabilitySuccess(
    res: { article: ReadabilityResult | null; title: string },
    base: string,
    url: string,
  ): HttpResponse {
    console.log(res)
    const article = res.article
    if (!article) {
      throw new Error('Error from readability')
    }
    const title = article.title ? article.title : res.title
    article.content = Html2Pdf.fixRelativeUrlsInHtmlText(article.content, base)
    //this.logSuccessfulImport(url)
    return {
      data: {
        status: 'success',
        content: article.content,
        url: url,
        title: title,
        base: base,
      },
      status: 'success',
    }
  }

  async doReadabilityFallback(url: string, content: string, title: string): Promise<HttpResponse> {
    try {
      const response = await fetch('parseUrlContent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          websiteUrl: url,
          html: content,
          title: title,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      let base = ''
      if (data.status === 'success') {
        let html = data.content
        base = data.base ? data.base : url
        html = Html2Pdf.fixRelativeUrlsInHtmlText(html, base)
        data.content = html
      }

      /*
        return new Response(JSON.stringify(data), {
            status: response.status,
            statusText: response.statusText,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        */
      return {
        data: {
          status: 'success',
          content: data.content,
          url: url,
          title: title,
          base,
        },
        status: 'success',
      }
    } catch (e) {
      throw new Error('error in reading from fallback')
    }
  }

  async doPhpReadability(response: TPhpReadabilityResponse): Promise<HttpResponse> {
    if (response.status === ReadabilityWithIframe.READABILITY_FAILURE) {
      const result = await this.doReadabilityFallback(response.url, response.content, response.title)
      return result
    } else {
      return {
        data: response,
        status: response.status,
      }
    }
  }

  async doFallbackReadability(html: string, base: string, url: string): Promise<HttpResponse> {
    const title = ''
    const response = await this.doPhpReadability({
      status: ReadabilityWithIframe.READABILITY_FAILURE,
      content: html,
      url: url,
      base: base,
      title: title,
      message: '' + 'Readability failed to extract content from url ' + url,
    })
    if (response.data.content) {
      response.data.content = Html2Pdf.fixRelativeUrlsInHtmlText(response.data.content, base)
    }
    return response
  }

  async doReadabilityFromUrl(url: string): Promise<HttpResponse> {
    const response = await this.getUrlContent(url)
    if (response.status !== 'success') {
      return response
    }
    const data = response.data
    const content = data.content
    const html = this.cleanHtmlFromErrors(content)
    response.data.content = html
    const base = response.data.base ? response.data.base : url

    try {
      const res: {
        article: ReadabilityResult | null
        title: string
      } = this.doReadability(url, html, base)
      if (res.article) {
        return this.onIframeReadabilitySuccess(res, base, url)
      }
    } catch (e) {
      console.error(e)
    }

    return this.doFallbackReadability(html, base, url)
  }
}
